let url = WPURLS.siteurl_themes;
let body = document.body;

//var sticky = new Sticky(".site-header");

window.onload = function (event) {
	body.classList.add("loaded");
};

window.onscroll = function () {};

window.onresize = function () {
	initResize();
};

docReady(function () {
	init();
});

(function ($) {
	function scrolOnPositionAddShow(scrollTop, positoinRef, $elementCible) {
		if (scrollTop > positoinRef && !$elementCible.hasClass("show")) {
			$elementCible.addClass("show");
		} else if (scrollTop < positoinRef && $elementCible.hasClass("show")) {
			$elementCible.removeClass("show");
		}
	}

	function scrollFixedMaptoBottom(scrollTop, positoinRef, $elementCible) {
		if (scrollTop > positoinRef && !$elementCible.hasClass("fixedtobottom")) {
			$elementCible.addClass("fixedtobottom");
		} else if (
			scrollTop < positoinRef &&
			$elementCible.hasClass("fixedtobottom")
		) {
			$elementCible.removeClass("fixedtobottom");
		}
	}

	var scrollTop = 0;
	var lastScrollTop = 0;

	$(window)
		.bind("scroll", function () {
			scrollTop = $(window).scrollTop();

			scrolOnPositionAddShow(scrollTop, 300, $("#backtotop"));

			var scrollTemp =
				parseInt($(".site-footer").offset().top, 10) -
				parseInt($("#mapid").outerHeight(true), 10) -
				50 -
				25;
			scrollFixedMaptoBottom(scrollTop, scrollTemp, $("#mapid"));

			var st = window.pageYOffset || document.documentElement.scrollTop;
			if (st > lastScrollTop) {
				// downscroll code
				if (!$("body").hasClass("scroll-down") && st > 50) {
					$("body").addClass("scroll-down");
				}
			} else {
				// upscroll code
				if ($("body").hasClass("scroll-down")) {
					$("body").removeClass("scroll-down");
				}
			}
			lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
		})
		.scroll();

	$(document).ready(function () {
		$(".site-header").sticky({ topSpacing: 0, zIndex: 9000 });
	});
})(jQuery);
